import React from "react"
import "./styles.scss"

const PolicyDescription = ({ children, sentence }) => {
    return (
        sentence ? (
            <p className="sentence">{children}</p>
        ) : (
            <div className="policy-description">{children}</div>
        )
    )
}

export default PolicyDescription
