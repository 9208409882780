import React, { useState, useEffect } from "react"
import Database from "../../_utils/Database"
import "./styles.scss"

export default function CookiesButton() {
    const [accepted, setAccepted] = useState(false)

    useEffect(() => {
        setAccepted(Database.getCookieAccepted())
    }, [])

    const accept = () => {
        Database.setCookieAccepted(true)
        setAccepted(true)
    }

    const reject = () => {
        Database.removeCookieAccepted()
        setAccepted(false)
    }

    return !accepted ? (
        <button
            className="btn btn-primary m-3"
            onClick={accept}
        >
            <span>Accept Cookies</span>
        </button>
    ) : (
        <button
            className="btn btn-primary m-3"
            onClick={reject}
        >
            <span>Reject Cookies</span>
        </button>
    )
}
